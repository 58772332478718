<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="workspace-users">
    <default-button
      v-if="isAdmin"
      class="workspace-users__invite-button"
      title="Invite member"
      icon-name="bx-user-plus"
      color="primary"
      form="rectangle"
      size="s"
      @action="modals.invite = true"
    />

    <default-input
      :value.sync="searchQuery"
      placeholder="Search"
      icon="bx-search"
      size="xs"
      class="workspace-users__search"
    />

    <div class="workspace-users__filters">
      <default-button
        v-for="{ title, value } in filters"
        :key="value"
        :title="title"
        :color="getFilterButtonColor(value)"
        form="ellipse"
        size="s"
        class="workspace-users__filter-button"
        :class="{ 'workspace-users__filter-button--active': selectedFilter === value }"
        @action="setFilter(value)"
      />
    </div>

    <div class="workspace-users__list">
      <span class="workspace-users__list-title">User</span>

      <users-user
        v-for="user in selectedFilter === 'active' ? account.users : pendingUsers"
        v-show="isUserVisible(user.full_name, user.email)"
        :key="user._id"
        class="workspace-users__list-item"
        :user="user"
        :is-pending="selectedFilter === 'pending'"
        @revoke-invite="revokeInvite"
      />
    </div>

    <users-invite-modal
      :show-modal.sync="modals.invite"
      @invite="fetchEmailInvites"
    />
  </div>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import UsersUser from '@/components/preferencesPage/workspace/users/user/UsersUser';
  import UsersInviteModal from '@/components/preferencesPage/workspace/users/UsersInviteModal';
  import DefaultInput from '@/components/base/uiKit/DefaultInput.vue';
  import { getEmailInvites } from '@/api/spaceMethods';
  import { mapGetters } from 'vuex';

  export default {
    name: 'WorkspaceUsers',
    components: {
      DefaultButton,
      UsersUser,
      UsersInviteModal,
      DefaultInput,
    },
    data () {
      return {
        filters: [
          {
            title: 'Active',
            value: 'active',
          },
          {
            title: 'Invite pending',
            value: 'pending',
          },
        ],
        selectedFilter: 'active',
        searchQuery: '',
        modals: {
          invite: false,
          isAdmin: false,
        },
        isAdmin: false,
        pendingUsers: [],
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
        user: 'user/user',
      }),
    },
    mounted () {
      this.fetchEmailInvites();
      this.isAdmin = this.account?.admins.includes(this.user._id);
    },
    methods: {
      async fetchEmailInvites () {
        this.pendingUsers = await getEmailInvites(this.account?._id);
      },
      revokeInvite (id) {
        this.pendingUsers = this.pendingUsers.filter(user => user._id !== id);
      },
      getFilterButtonColor (filter) {
        return filter === this.selectedFilter ? 'primary' : '';
      },
      setFilter (filter) {
        this.selectedFilter = filter;
      },
      isUserVisible (fullName = '', email = '') {
        const queryFormatted = this.searchQuery.trim().toLowerCase();
        const nameMatch = fullName.toLowerCase().includes(queryFormatted);
        const emailMatch = email.toLowerCase().includes(queryFormatted);
        return nameMatch || emailMatch;
      },
    },
  };
</script>
<style lang="scss">
.workspace-users {
  position: relative;
  &__invite-button {
    position : absolute;
    top: -42px;
    right: 0;
  }

  &__search {
    max-width: 240px;
    margin-bottom: 24px;
  }

  &__filters {
    display: flex;
    gap: 4px;
    margin-bottom: 16px;
  }

  &__filter-button {
    border: 1px solid;
    border-color: #e2e2e2;

    &:hover {
      border-color: var(--primary-cl);
    }

    &--active {
      border-color: var(--primary-cl);
    }
  }

  &__list {
    &-title {
      @include text-xs;
      color: var(--color-text-tetriary);
      margin-left: 16px;
    }

    &-item {
      margin-top: 8px;
    }
  }
}
</style>
